<template>
  <DetectionResult />
</template>

<script>
import DetectionResult from '@/views/burn-detection/DetectionResult.vue'

export default {
  components: {
    DetectionResult
  },
}
</script>
